<template>
  <popup-dialog title="订阅" class="chinese-project" @close="close" v-if="visible">
    <div class="chinese-project-wrap">
      <div class="members-vip">
        <div class="price-list">
          <div v-for="(item, index) in dataInfo.user_level_details" :class="{'price-list_item-cell': true, 'active': item.id == priceObj.id} " :key="index" @click="choosePrice(item)">
            <div class="month">{{item.name}}</div>
            <div class="price">
              <span class="unit">
                {{ mode == 1 ? '￥' : '$' }}
              </span>
              <template v-if="mode == 1">
                <span v-if="item.first_time_price">{{item.first_time_price}}</span>
                <span v-else-if="item.fact_price">{{item.fact_price}}</span>
                <span v-else>{{item.price}}</span>
              </template>
              <template v-else>
                <span v-if="item.usd_first_price">{{item.usd_first_price}}</span>
                <span v-else-if="item.usd_fact_price">{{item.usd_fact_price}}</span>
                <span v-else>{{item.usd_price}}</span>
              </template>
            </div>
            <div class="const-price" v-if="mode == 1 && (item.first_time_price || item.fact_price)">
              原价￥{{ item.price }}
            </div>
            <div class="const-price" v-else-if="mode == 2 && (item.usd_first_price || item.usd_fact_price)">
              原价 $ {{ item.usd_price }}
            </div>
            <div class="vip-badge" v-if="item.most_buy_count">
              最多人购买
            </div>
            <div class="vip-badge" v-else-if="item.first_time_price">
              新用户首月
            </div>
          </div>
        </div>
        <el-empty description="暂无数据" v-if="!dataInfo.user_level_details || dataInfo.user_level_details.length == 0"></el-empty>
      </div>
      <!-- 学生资料，学生身份需要上传 -->
      <div class="members-upload" @click="openStudent" v-if="dataInfo.examine == 1 && !dataInfo.is_examine">
        <div class="title">
          <span>学生资料</span>
        </div>
        <div class="desc">
          <span>点击填写学生资料</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="members-remark">
        <div class="accord">
          <span>请仔细阅读CF40研究</span>
          <span class="text-link" @click="openProtocol('','MEMBERSHIP_SERVICE_AGREEMENT')">《会员服务协议》</span>
          <span class="text-link" @click="openProtocol('','PRIVACY_CLAUSE')">《隐私条款》</span>
          <span>，支付即表示同意</span>
        </div>
        <div class="tip" v-if="dataInfo.tips">
          {{ dataInfo.tips }}
        </div>
      </div>
      <div class="pay-mode" v-if="dataInfo.type == 1 && (dataInfo.examine != 1 || dataInfo.is_examine)">
        <el-radio-group v-model="mode">
          <el-radio :label="1">微信支付</el-radio>
          <el-radio :label="2">PayPal支付</el-radio>
        </el-radio-group>
      </div>
      <div class="opera-submit" v-if="dataInfo.type == 1">
        <template v-if="dataInfo.examine == 1 && !dataInfo.is_examine">
          <div class="opera-submit_tips">
            请先填写资料,等待资料审核通过后可购买
          </div>
        </template>
        <template v-else>
          <div class="opera-submit_total">
            <span>合计：</span>
            <span class="price">{{ mode == 1 ? '￥' : '$ ' }}{{ payPrice }}</span>
          </div>
          <el-button type="danger" :loading="payLoading" @click="createOrder" size="medium" :disabled="dataInfo.examine == 1 && !dataInfo.is_examine" round>确认支付{{ mode == 1 ? '￥' : ' $ ' }}{{ payPrice }}</el-button>
        </template>
      </div>
    </div>
    <!-- 会员服务协议 -->
    <service-agreement ref="serviceAgreementRef"></service-agreement>
    <!-- 会员认证 -->
    <member-student ref="memberStudentRef" @success="studentSuccess"></member-student>
    <!-- 支付的二维码窗口 -->
    <payment-qrcode ref="paymentQrcodeRef" @success="close" @callback="close"></payment-qrcode>
  </popup-dialog>
</template>

<script>
import PopupDialog from "@/components/popupDialog";
import MemberStudent from "@/components/memberStudent";
import ServiceAgreement from "@/components/serviceAgreement";
import PaymentQrcode from "@/components/paymentQrcode";
import { createVipOrder, payPalDefray, initConfig } from "@/api"
export default {
  name: "ChineseProject",
  components: {
    PopupDialog,
    MemberStudent,
    ServiceAgreement,
    PaymentQrcode
  },
  data() {
    return {
      // 窗口是否显示
      visible: false,
      // 数据
      dataInfo: {},
      // 选中的价格信息
      priceObj: {},
      // 支付方式
      mode: 1,
      // 支付状态
      payLoading: false,
    }
  },
  computed: {
    // 支付金额
    payPrice() {
      if (this.mode == 1) {
        if (this.priceObj && this.priceObj.first_time_price) {
          return this.priceObj.first_time_price;
        } else if (this.priceObj && this.priceObj.fact_price) {
          return this.priceObj.fact_price;
        } else if (this.priceObj && this.priceObj.price) {
          return this.priceObj.price;
        } else {
          return false;
        }
      } else {
        if (this.priceObj && this.priceObj.usd_first_price) {
          return this.priceObj.usd_first_price;
        } else if (this.priceObj && this.priceObj.usd_fact_price) {
          return this.priceObj.usd_fact_price;
        } else if (this.priceObj && this.priceObj.usd_price) {
          return this.priceObj.usd_price;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    // 打开窗口
    open(row, selectLevelId) {
      if (row.id) {
        this.initPrice(row.user_level_details, selectLevelId);
        this.dataInfo = row;
        this.visible = true;
      }
    },
    // 关闭窗口
    close () {
      this.visible = false;
    },
    // 初始化选中价格
    initPrice(rows, levelId) {
      if (rows && rows.length > 0) {
        let temp_index = 0;
        for (let i = 0; i < rows.length; i++) {
          let item = rows[i];
          if (levelId && levelId > 0) {
            if (item.id == levelId) {
              temp_index = i;
              break;
            }
          } else if (item.most_buy_count) {
            temp_index = i;
            break;
          }
        }
        this.priceObj = rows[temp_index];
      }
    },
    // 选择价格
    choosePrice(e) {
      this.priceObj = e;
    },
    // 打开上传学生资料
    openStudent() {
      this.$refs.memberStudentRef.open();
    },
    // 学生资料填写成功
    studentSuccess() {
      this.dataInfo.is_examine = true;
    },
    // 打开会员协议
    async openProtocol(name, key) {
      let res = await initConfig();
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == key) {
              this.companyEmail = item.config_value;
              this.$refs.serviceAgreementRef.open(name, item.config_value);
              break;
            }
          }
        }
      }
    },
    // 获取返回链接
    getBackUrl() {
      return `${window.location.protocol}//${window.location.host}${window.location.pathname}?retracement=success`;
    },
    // paypal支付
    async payPalHandle(orderId) {
      this.payLoading = true;
      let res = await payPalDefray({ order_id: orderId, return_url: this.getBackUrl() });
      if (res && res.code == 200) {
        if (res.data && res.data.response && res.data.response.links && res.data.response.links.length > 0) {
          let payUrl = res.data.response.links[res.data.response.links.length - 1]['href'];
          if (payUrl) {
            window.location.href = payUrl;
          }
        }
      }
      this.payLoading = false;
    },
    //下单
    async createOrder() {
      if (this.priceObj){
        let token = localStorage.getItem("cf40-token");
        if (token) {
          let res = await createVipOrder({user_level_detail_id:this.priceObj.id, pay_web_type:this.mode==2 ? 5 : 3});
          if (res && res.code == 200) {
            if (this.mode == 1) {
              this.$refs.paymentQrcodeRef.open(res.data.order.id);
            } else {
              this.payPalHandle(res.data.order.id);
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chinese-project {
  font-family: HarmonyOS_Sans_Regular;
  ::v-deep {
    .popup-wrap {
      width: 600px;
      &-content {
        margin-top: 0;
      }
    }
  }
  &-wrap {
    .members-vip {
      .price-list {
        display: flex;
        flex-wrap: wrap;
        &_item-cell {
          margin-top: 25px;
          width: 121px;
          min-width: 75px;
          position: relative;
          padding: 15px 15px;
          border-radius: 10px;
          background-color: #f3f3f3;
          border: 3px solid #f3f3f3;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 15px;
          cursor: pointer;
          z-index: 1;
          .month {
            font-size: 16px;
            color: #000000;
          }
          .price {
            color: #000000;
            font-size: 28px;
            font-family: "HarmonyOS_Bold";
            padding: 12px 0;
            display: flex;
            .unit {
              font-size: 13px;
              color: #7c7b7b;
              font-weight: 500;
              padding-top: 4px;
            }
          }
          .const-price {
            font-size: 14px;
            color: #8c8b8b;
          }
          &:last-child {
            margin-right: 0;
          }
          &.active {
            background-color: #fae9e1;
            border: 3px solid #ed6f33;
          }
          .vip-badge {
            position: absolute;
            top: -15px;
            left: -3px;
            color: #ffffff;
            font-size: 12px;
            padding: 5px 10px;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            border-bottom-right-radius: 8px;
            background-color: #ed6f33;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 30;
          }
        }
      }
      .desc {
        padding-top: 15px;
        text-align: center;
        font-size: 14px;
        color: #a8a8a8;
      }
    }
    .members-upload {
      margin-top: 15px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ebeef5;
      .desc {
        flex: 1;
        text-align: right;
        cursor: pointer;
        user-select: none;
        &:hover {
          color: #ed6f33;
        }
      }
    }
    .members-remark {
      .accord {
        margin-top: 20px;
        font-size: 14px;
        color: #000000;
        .text-link {
          color: #ed6f33;
          user-select: none;
          cursor: pointer;
        }
      }
      .tip {
        padding-top: 8px;
        line-height: 20px;
        font-size: 14px;
        color: #777777;
      }
    }
    .pay-mode {
      margin-top: 8px;
      font-size: 14px;
      ::v-deep {
        .el-radio__input.is-checked+.el-radio__label {
          color: #ed6f33;
        }
        .el-radio__input.is-checked .el-radio__inner {
          border-color: #ed6f33;
          background: #ed6f33;
        }
        .el-radio__inner:hover {
          border-color: #ed6f33;
        }
      }
    }
    .opera-submit {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_tips {
        width: 100%;
        font-size: 14px;
        color: #dd7643;
        font-family: "HarmonyOS_Bold";
        display: flex;
        justify-content: flex-end;
      }
      &_total {
        display: flex;
        align-items: center;
        .price {
          color: #dd7643;
          font-size: 18px;
          font-family: "HarmonyOS_Bold";
        }
      }
      ::v-deep {
        .el-button--danger {
          background-color: #ed6f33;
          border-color: #ed6f33;
          &:hover, &:active {
            background-color: #f7844d;
            border-color: #f7844d;
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .chinese-project {
    ::v-deep {
      .popup-wrap {
        width: 90%;
        &-content {
          margin-top: 0;
        }
      }
    }
    &-wrap {
      .members-vip {
        .price-list {
          &_item-cell {
            width: auto;
          }
        }
      }
      .opera-submit {
        &_total {
          .price {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>