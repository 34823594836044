<template>
  <popup-dialog title="学生认证" @close="close" v-if="visible">
    <div class="member-student" v-loading="loading">
      <!-- 审核成功 -->
      <el-result icon="success" title="审核成功" subTitle="您的资料已审核通过，可以购买对应会员权益" v-if="info.status==1"></el-result>
      <!-- 等待审核 -->
      <el-result icon="info" title="等待审核中..." subTitle="请耐心等待，我们会尽快处理" v-if="info.status==0"></el-result>
      <!-- 审核驳回 -->
      <el-result icon="error" title="审核驳回" :subTitle="info.remark ? '驳回原因：'+info.remark : '请上传真实有效证件信息'" v-if="info.status==2"></el-result>
      <el-form :model="subForm" :rules="rules" ref="subForm" label-width="80px" v-if="JSON.stringify(info) == '{}' || info.status==2">
        <el-form-item label="真实姓名" prop="name">
          <el-input v-model="subForm.name" placeholder="请输入您的真实姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="出生日期" prop="born_date">
          <el-date-picker v-model="subForm.born_date" type="date" value-format="yyyy-MM-dd" placeholder="请选输入您的出生年月"></el-date-picker>
        </el-form-item>
        <el-form-item label="所在学校" prop="school_name">
          <el-input v-model="subForm.school_name" placeholder="请输入您所在学校全称" clearable></el-input>
        </el-form-item>
        <el-form-item label="毕业时间" prop="grad_date">
          <el-date-picker v-model="subForm.grad_date" type="date" value-format="yyyy-MM-dd" placeholder="请选输您的毕业时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="所在地区" prop="area">
          <el-cascader v-model="subForm.area" :options="areaList"></el-cascader>
        </el-form-item>
        <el-form-item label="学历" prop="education_id">
          <el-select v-model="subForm.education_id" placeholder="请选择学历">
            <el-option
                v-for="(item, index) in educationList"
                :key="index"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item align="right">
          <el-button type="danger" :loading="subLoading" @click="saveForm">提 交</el-button>
          <el-button :loading="subLoading" @click="close">取 消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </popup-dialog>
</template>

<script>
import { vipExamine, getVipExamine, regionList } from "@/api/index";
import PopupDialog from "@/components/popupDialog";
export default {
  name: "MemberStudent",
  components: {
    PopupDialog
  },
  data() {
    return {
      visible: false,
      loading: false,
      subLoading: false,
      info: {},
      subForm: {
        name: "",
        born_date: "",
        school_name: "",
        grad_date: "",
        province_id: "",
        city_id: "",
        area_id: "",
        area: [],
        education_id: "",
      },
      rules: {
        name: [
          { required: true, message: '请输入您的真实姓名', trigger: 'blur' },
        ],
        born_date: [
          { required: true, message: '请选择您的出生日期', trigger: 'change' },
        ],
        school_name: [
          { required: true, message: '请输入您的所在院校全称', trigger: 'blur' },
        ],
        grad_date: [
          { required: true, message: '请选择您的毕业事件', trigger: 'blur' },
        ],
        area: [
          { required: true, message: '请选择您的所在地区', trigger: 'change' },
        ],
        education_id: [
          { required: true, message: '请选择您的学历', trigger: 'change' },
        ]
      },
      educationList: [
        { id: "1", name: "大专" },
        { id: "2", name: "本科" },
        { id: "3", name: "研究生" },
        { id: "4", name: "博士" },
      ],
      areaList: []
    }
  },
  methods: {
    // 打开窗口
    open() {
      this.getAreaList();
      this.getVipExamineStatus();
      this.visible = true;
    },
    // 关闭窗口
    close() {
      this.visible = false;
    },
    // 获取区域
    async getAreaList() {
      let res = await regionList();
      if (res && res.code == 200) {
        this.areaList = res.data;
      }
    },
    // 查询VIP审核状态
    async getVipExamineStatus() {
      this.loading = true;
      let res = await getVipExamine();
      if (res && res.code == 200) {
        // 没有填写值是 {}
        this.info = res.data;
      }
      this.loading = false;
    },
    // 绑定表单
    bindData() {
      if (JSON.stringify(this.info) !="{}") {
        this.subForm = JSON.parse(JSON.stringify(this.info));
      } else {
        this.subForm = {
          name: "",
          born_date: "",
          school_name: "",
          grad_date: "",
          province_id: "",
          city_id: "",
          area_id: "",
          education_id: "",
        };
      }
    },
    // 数据处理
    submitHandle() {
      let obj = JSON.parse(JSON.stringify(this.subForm));
      if (obj.area && obj.area.length == 3) {
        obj.province_id = obj.area[0];
        obj.city_id = obj.area[1];
        obj.area_id = obj.area[2];
        delete obj.area;
        return obj;
      } else {
        this.$message.error("请选择您的所在区域");
        return false;
      }
    },
    // 保存
    saveForm() {
      this.$refs.subForm.validate(async (valid) => {
        if (valid) {
          this.subLoading = true;
          if (this.submitHandle()) {
            let res = await vipExamine(this.submitHandle());
            if (res && res.code == 200) {
              this.$message.success(res.msg ? res.msg : "操作成功");
              this.$emit("success");
              this.close();
            } else {
              this.$message.error(res && res.msg ? res.msg : "操作失败，请稍后重试");
            }
          }
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-date-editor, .el-select, .el-cascader {
    width: 100%;
  }
  .popup-wrap {
    width: 500px !important;
    &-content {
      margin-top: 30px !important;
    }
  }
  .el-button--danger {
    background-color: #ed6f33;
    border-color: #ed6f33;
    &:hover, &:active {
      background-color: #f7844d;
      border-color: #f7844d;
    }
  }
  .el-button--default {
    &:hover, &:active {
      color: #ed6f33;
      background-color: #f9d1be;
      border-color: #f7844d;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
</style>